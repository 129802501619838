<!-- 耕雲導師简历 -->

<template>
  <!-- 耕雲導師墨寶 -->
  <div class="contenBrief" v-html="brief.news_content" >

  </div>
</template>

<script>
import {getCateList,getResume} from '../../api/apis'
  export default {
    name: "",
    components: {
      // barF5
    },
    props: {},
    data() {
      return {
       
        brief:{}
      };
    },
    methods: {
     
    },
    created(){
     getCateList().then(res => {
       let id = res[0][0].id;
       getResume(id).then(res => {
         this.brief = res;
       })
     })
      
    }
  };
</script>

<style lang="stylus" scoped>
 .contenBrief{
   line-height 28px;
 }
</style>